<template>
  <label class="button">
    <input :class="{ answer, 'not-answer': answer !== null && !answer }" :id="id" :name="name" :type="type" :value="index" :disabled="disabled" :checked="_value" @input="_value = index" />
    <span></span>
  </label>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: str => ['radio', 'checkbox'].includes(str)
    },
    index: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: 0
    },
    value: {
      type: Array,
      default: () => []
    },
    answer: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    id () {
      return `${this.name}_${this.index}`
    },
    disabled () {
      return this.max > 0 && !this._value && this.value.length >= this.max
    },
    _value: {
      get () {
        return this.value.includes(this.index)
      },
      set (value) {
        if (this.type === 'checkbox') {
          if (this.value.includes(value)) {
            this.$emit('input', this.value.filter(x => x !== value))
          } else {
            this.$emit('input', [...this.value, value])
          }
        } else {
          this.$emit('input', [value])
        }
      }
    }
  }
}
</script>
