<template>
  <div id="app" class="predictions">
    <div class="container">
      <div class="section">
        <app-button v-for="i in range(6)" :key="i" name="theme" type="radio" :index="i" v-model="state['theme']" :answer="answer('theme', i)" />
      </div>
      <div class="section large">
        <app-button v-for="i in range(12)" :key="i" name="characters" type="checkbox" :max="3" :index="i" v-model="state['characters']" :answer="answer('characters', i)" />
      </div>
      <div class="section large">
        <app-button v-for="i in range(12)" :key="i" name="plot" type="checkbox" :max="2" :index="i" v-model="state['plot']" :answer="answer('plot', i)" />
      </div>
      <div class="section large">
        <app-button v-for="i in range(12)" :key="i" name="activities" type="checkbox" :max="3" :index="i" v-model="state['activities']" :answer="answer('activities', i)" />
      </div>
      <div class="section small">
        <app-button v-for="i in range(18)" :key="i" name="exotic" type="radio" :index="i" v-model="state['exotic']" :answer="answer('exotic', i)" />
      </div>
      <div class="section">
        <app-button v-for="i in range(6)" :key="i" name="dungeon" type="radio" :index="i" v-model="state['dungeon']" :answer="answer('dungeon', i)" />
      </div>
      <div class="section x-large">
        <app-button v-for="i in range(6)" :key="i" name="weapon" type="checkbox" :max="2" :index="i" v-model="state['weapon']" :answer="answer('weapon', i)" />
      </div>
      <div class="section">
        <app-button v-for="i in range(2)" :key="i" name="element" type="radio" :index="i" v-model="state['element']" :answer="answer('element', i)" />
      </div>
      <a class="share" :href="shareLink" target="_blank">
        SHARE
      </a>
      <button class="reset" @click="reset">
        RESET
      </button>
      <span class="change">Keep in mind that some of the results are yet to be confirmed, so please take them with a grain of salt.</span>
      <span class="score">{{ score }}</span>
      <div class="footer"></div>
      <a class="credit-original" href="https://twitter.com/freddyalabastro" target="_blank"></a>
      <span class="credit-website">This website is an adaptation by <span>Binarmorker @binarmorker</span><a href="https://twitter.com/binarmorker" target="_blank"></a></span>
    </div>
  </div>
</template>

<script>
import { Buffer } from 'buffer'
import AppButton from './components/AppButton.vue'

const clone = obj => JSON.parse(JSON.stringify(obj))
const defaultState = {
  theme: [],
  characters: [],
  plot: [],
  activities: [],
  exotic: [],
  dungeon: [],
  weapon: [],
  element: []
}

export default {
  components: {
    AppButton
  },
  data () {
    return {
      state: clone(defaultState),
      goodGuesses: {
        theme: [1, 5],
        characters: [3, 4, 9, 11],
        plot: [2, 9],
        activities: [4, 10, 11, 12],
        exotic: [15],
        dungeon: [2],
        weapon: [1],
        element: [2]
      }
    }
  },
  computed: {
    hasPredictions () {
      return Object.values(this.state).some(x => x.length)
    },
    score () {
      let _score = 0

      Object.keys(this.goodGuesses).forEach(key => {
        _score += this.state[key].reduce((acc, x) => acc + (this.goodGuesses[key].includes(x) ? 10 : -5), 0)
      })

      return _score
    },
    canonical () {
      return this.hasPredictions ? `${location.origin}${location.pathname}${this.encodedState}` : `${location.origin}${location.pathname}`
    },
    encodedState () {
      return '?' + Buffer.from(JSON.stringify(this.state), 'utf8').toString('base64')
    },
    shareLink () {
      const text = this.hasPredictions
        ? 'Here\'s my prediction regarding Season 17 of Destiny 2!'
        : 'Predict with me what the Season 17 of Destiny 2 will be like!'
      return `https://twitter.com/intent/tweet?text=${text.replaceAll(' ', '%20')}&url=${this.canonical}`
    }
  },
  methods: {
    range (a, b = null) {
      return Array.from({ length: b ? b - a : a }, (_, i) => 1 + (b ? b + i : i))
    },
    answer (name, index) {
      return (this.goodGuesses[name] && this.goodGuesses[name].length) ? this.goodGuesses[name].includes(index) : null
    },
    reset () {
      this.state = clone(defaultState)
    }
  },
  created () {
    try {
      this.state = Object.assign(clone(defaultState), location.search ? JSON.parse(Buffer.from(location.search.replace('?', ''), 'base64').toString('utf8')) : {})
      this.state = Object.keys(this.state).reduce((acc, key) => {
        acc[key] = this.state[key].map(x => +x)
        return acc
      }, {})
    } catch (e) {
      console.log(e)
    }
  },
  watch: {
    state: {
      handler () {
        document.querySelector('link[rel=canonical]').setAttribute('href', this.canonical)
        history.replaceState({}, '', this.hasPredictions ? this.encodedState : location.pathname)
      },
      deep: true
    }
  }
}
</script>

<style>
  @font-face {
    font-family: 'Neue Haas Grotesk';
    src: url('~@/assets/NeueHaasGrotesk.ttf');
  }

  @font-face {
    font-family: 'Neue Haas Grotesk Bold';
    src: url('~@/assets/NeueHaasGroteskBold.ttf');
  }

  html, body {
    padding: 0;
    margin: 0;
    background: #000000;
  }

  .predictions {
    background: url('~@/assets/background.png') #000000 top center no-repeat;
    width: 1190px;
    height: 2404px;
    margin: 0 auto;
  }

  .container {
    padding: 436px 60px 60px;
    position: relative;
  }

  .section {
    padding-top: 62px;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .button {
    display: block;
    height: 80px;
    min-width: 160px;
    flex: 1;
  }

  .section.small .button {
    height: 74px;
  }

  .section.large .button {
    height: 90px;
  }

  .section.x-large .button {
    height: 94px;
  }

  .button input {
    display: none;
  }

  .button span {
    display: block;
    height: 100%;
    width: 100%;
  }

  .button:hover input:not(:disabled) ~ span {
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }

  .button input:checked ~ span {
    background: rgba(255, 255, 255, 0.4);
  }

  .button input.not-answer:checked ~ span {
    background: rgba(255, 0, 0, 0.4);
  }

  .button input.answer ~ span {
    background: rgba(0, 255, 127, 0.2);
  }

  .button input.answer:checked ~ span {
    background: rgba(0, 255, 127, 0.6);
  }

  .change {
    position: absolute;
    top: 300px;
    left: 260px;
    width: 638px;
    height: 42px;
    background: rgba(0, 0, 0, 0.8);
    padding: 40px 16px 16px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-family: 'Neue Haas Grotesk', serif;
  }

  .change::before {
    content: 'THE RESULTS ARE IN!';
    position: absolute;
    margin-top: -28px;
    color: #ffffff;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Neue Haas Grotesk Bold', serif;
  }

  .reset {
    position: absolute;
    top: 180px;
    right: 160px;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    height: 72px;
    border: 0;
    width: 96px;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Neue Haas Grotesk Bold', serif;
    cursor: pointer;
  }

  .reset:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .share {
    position: absolute;
    top: 180px;
    right: 60px;
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    height: 72px;
    border: 0;
    width: 96px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Neue Haas Grotesk Bold', serif;
    cursor: pointer;
  }

  .share:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .score {
    position: absolute;
    top: 300px;
    right: 60px;
    color: #ffffff;
    height: 72px;
    width: 196px;
    font-weight: 600;
    font-size: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Neue Haas Grotesk Bold', serif;
  }

  .footer {
    position: absolute;
    bottom: 0;
    height: 50px;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 75%);
  }

  .credit-original {
    position: absolute;
    top: 2304px;
    right: 382px;
    height: 30px;
    width: 224px;
  }

  .credit-original:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .credit-website {
    position: absolute;
    top: 2334px;
    height: 30px;
    width: 1190px;
    margin: 0 -60px;
    color: #cccccc;
    border: 0;
    opacity: 0.8;
    text-align: center;
    line-height: 30px;
    font-weight: 100;
    font-size: 16px;
    font-family: 'Neue Haas Grotesk', serif;
  }

  .credit-website span {
    display: inline-block;
    height: 30px;
    width: 212px;
    color: #ffffff;
    background-color: #f3ec78;
    background-image: linear-gradient(45deg, #f3ec78, #af4261);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .credit-website a {
    position: absolute;
    right: 368px;
    display: inline-block;
    height: 30px;
    width: 212px;
  }

  .credit-website a:hover {
    background: rgba(255, 255, 255, 0.2);
  }
</style>
